import React, { useState } from 'react';
import LocalForage from 'localforage';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './../Styles/ExtraInfo.css';
import submitData from './submitData';

const ExtraInfo = () => {
  const [addSelected, setAddSelected] = useState(false); //Hook to disable/enable submit or add comment buttons.
  const [arr, setArr] = useState([]); //Hook for registering selected info to local storage

  const location = useLocation();	//This hook is used to receive data from the parent componenet (Rating)
  const result = location.state.selected;


  const handleClick = (e) => {
    const selector = e.target.name
    const element = document.querySelector(`[name = ${selector}]`);
		
		//Style selected options and push it to the array.
    if (element.classList.contains('ExtraInfo-style-selected')) {
      element.classList.remove('ExtraInfo-style-selected');
      let newList = arr;
      const index = newList.indexOf(element.textContent);
      newList.splice(index, 1);
      setArr(newList);
    } else {
      let newList = arr;
      element.classList.add('ExtraInfo-style-selected');
      newList.push(element.textContent)
      setArr(newList);
    }
    LocalForage.setItem('Extra Info', arr);

    if (arr.length === 0) {
      setAddSelected(false);
    } else {
      setAddSelected(true);
    }
  }

  const handleSubmit = () => {
    submitData.submitData();
  }

    return (
      <div className="ExtraInfo-container">
        <div className="ExtraInfo-header">
          <div className="ExtraInfo-header-content">
          <span className="ExtraInfo-span">
            YOU HAVE RATED YOUR INTERVIEWER
          </span>
          {result === 'Awesome' ?
            <div className="ExtraInfo-card-awesome">
            <div>
              <img
                className="ExtraInfo-icon-awesome"
                src={require('./../../Assets/icon_awesome.png')}
                alt="Logo"
              />
            </div>
            <div className="ExtraInfo-content-awesome">
              <span className="ExtraInfo-awesome-tag">Awesome</span>
              <span className="ExtraInfo-awesome-line">
                Best interviewer ever!
              </span>
                </div>
                <Link className="ExtraInfo-change-container" exact to='/rating'>
            <div className="ExtraInfo-awesome-change">
                    CHANGE
            </div>
                  </Link>
            </div>
            :
            result === 'Good' ?
            <div className="ExtraInfo-card-good">
            <div>
              <img
                className="ExtraInfo-icon-good"
                src={require('./../../Assets/icon_awesome-icons_good.png')}
                alt="Logo"
              />
            </div>
            <div className="ExtraInfo-content-good">
              <span className="ExtraInfo-good-tag">Good</span>
              <span className="ExtraInfo-good-line">
                Nice Person, Really nice.
              </span>
                  </div>
                  <Link className="ExtraInfo-change-container" exact to='/rating'>
            <div className="ExtraInfo-good-change">
                      CHANGE
            </div>
                    </Link>
              </div>
              :
              result === 'Neutral' ?
              <div className="ExtraInfo-card-neutral">
            <div>
              <img
                className="ExtraInfo-icon-neutral"
                src={require('./../../Assets/icon_awesome-icons_neutral.png')}
                alt="Logo"
              />
            </div>
            <div className="ExtraInfo-content-neutral">
              <span className="ExtraInfo-neutral-tag">Neutral</span>
              <span className="ExtraInfo-neutral-line">
                Ummm... Okay I guess.
              </span>
                    </div>
                    <Link className="ExtraInfo-change-container" exact to='/rating'>
            <div className="ExtraInfo-neutral-change">
                        CHANGE
            </div>
                      </Link>
                </div>
                :
                result === 'Bad' ?
                <div className="ExtraInfo-card-bad">
            <div>
              <img
                className="ExtraInfo-icon-bad"
                src={require('./../../Assets/icon_awesome-icons_bad.png')}
                alt="Logo"
              />
            </div>
            <div className="ExtraInfo-content-bad">
              <span className="ExtraInfo-bad-tag">Bad</span>
              <span className="ExtraInfo-bad-line">
                Needs to improve. A LOT!
              </span>
                      </div>
                      <Link className="ExtraInfo-change-container" exact to='/rating'>
            <div className="ExtraInfo-bad-change">
                          CHANGE
            </div>
                        </Link>
                  </div>
                  : null
          }
          </div>
          </div>
        <div className="ExtraInfo-feedback">
          <span className="ExtraInfo-question">
            What made the interviewers {result}?
          </span>
          <div className="ExtraInfo-choices">
            <button className='ExtraInfo-style' onClick={handleClick} name="politeness">Politeness</button>
            <button className='ExtraInfo-style' onClick={handleClick} name="Professionalism">Professionalism</button>
            <button className='ExtraInfo-style' onClick={handleClick} name="Expertise">Expertise</button>
            <button className='ExtraInfo-style' onClick={handleClick} name="Guidance">Guidance</button>
            <button className='ExtraInfo-style' onClick={handleClick} name="Attentiveness">Attentiveness</button>
            <button className='ExtraInfo-style' onClick={handleClick} name="Questions">Questions asked</button>
            <button className='ExtraInfo-style' onClick={handleClick} name="Quality">Quality of questions</button>
          </div>
        <div className="ExtraInfo-footer">
            <Link
              className="ExtraInfo-go-to-comment"
              exact to={"/comment"}>
            <button  disabled={!addSelected} className="ExtraInfo-comment"><FontAwesomeIcon icon="comment-alt" />&ensp;ADD COMMENT</button>
            </Link>
            <Link
              className="ExtraInfo-link"
              exact to={addSelected ? "/thanks" : "/extrainfo"}>
              <button onClick={handleSubmit} disabled={!addSelected} className={addSelected ?  "ExtraInfo-button-enabled" : "ExtraInfo-button"}>Submit&ensp;&emsp; <FontAwesomeIcon icon="check" /> </button>
            </Link>
          </div>
          </div>
      </div>
    );
  }

export default ExtraInfo;
