
const RatingHelper = (selected) => {
  const ratingSelectorOne = document.querySelector('.Rating-selector-one');
  const ratingAwesomeLine = document.querySelector('.Rating-awesome-line');
  const ratingSelectorTwo = document.querySelector('.Rating-selector-two');
  const ratingGoodLine = document.querySelector('.Rating-good-line');
  const ratingSelectorThree = document.querySelector(
    '.Rating-selector-three',
  );
  const ratingNeutralLine = document.querySelector('.Rating-neutral-line');
  const ratingSelectorFour = document.querySelector('.Rating-selector-four');
  const ratingBadLine = document.querySelector('.Rating-bad-line');

  if (selected === 'Awesome') {
    ratingSelectorOne.classList.add('Rating-selector-one-selected');
    ratingAwesomeLine.classList.add('Rating-awesome-line-selected');
    ratingSelectorTwo.classList.remove('Rating-selector-two-selected');
    ratingGoodLine.classList.remove('Rating-good-line-selected');
    ratingSelectorThree.classList.remove('Rating-selector-three-selected');
    ratingNeutralLine.classList.remove('Rating-neutral-line-selected')
    ratingSelectorFour.classList.remove('Rating-selector-four-selected');
    ratingBadLine.classList.remove('Rating-bad-line-selected');
  } else if (selected === 'Good') {
    ratingSelectorTwo.classList.add('Rating-selector-two-selected');
    ratingGoodLine.classList.add('Rating-good-line-selected');
    ratingSelectorOne.classList.remove('Rating-selector-one-selected');
    ratingAwesomeLine.classList.remove('Rating-awesome-line-selected');
    ratingSelectorThree.classList.remove('Rating-selector-three-selected');
    ratingNeutralLine.classList.remove('Rating-neutral-line-selected');
    ratingSelectorFour.classList.remove('Rating-selector-four-selected');
    ratingBadLine.classList.remove('Rating-bad-line-selected');

  } else if (selected === 'Neutral') {
    ratingSelectorThree.classList.add('Rating-selector-three-selected');
    ratingNeutralLine.classList.add('Rating-neutral-line-selected');
    ratingSelectorOne.classList.remove('Rating-selector-one-selected');
    ratingAwesomeLine.classList.remove('Rating-awesome-line-selected');
    ratingSelectorTwo.classList.remove('Rating-selector-two-selected');
    ratingGoodLine.classList.remove('Rating-good-line-selected');
    ratingSelectorFour.classList.remove('Rating-selector-four-selected');
    ratingBadLine.classList.remove('Rating-bad-line-selected');

  } else if (selected === 'Bad') {
    ratingSelectorFour.classList.add('Rating-selector-four-selected');
    ratingBadLine.classList.add('Rating-bad-line-selected');
    ratingSelectorTwo.classList.remove('Rating-selector-two-selected');
    ratingSelectorOne.classList.remove('Rating-selector-one-selected');
    ratingAwesomeLine.classList.remove('Rating-awesome-line-selected');
    ratingGoodLine.classList.remove('Rating-good-line-selected');
    ratingSelectorThree.classList.remove('Rating-selector-three-selected');
    ratingNeutralLine.classList.remove('Rating-neutral-line-selected');
  }
}

export { RatingHelper };