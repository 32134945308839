import React, { useState } from 'react';
import LocalForage from 'localforage';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './../Styles/Rating.css';
import { RatingHelper } from './RatingHelper';

const Rating = () => {
  const [selected, setSelected] = useState(''); //Hook for selected rating status

  RatingHelper(selected);
  if (selected === 'Awesome') {
    LocalForage.setItem('Rating', 'Awesome')
  } else if (selected === 'Good') {
    LocalForage.setItem('Rating', 'Good')
  } else if (selected === 'Neutral') {
    LocalForage.setItem('Rating', 'Neutral')
  } else if (selected === 'Bad') {
    LocalForage.setItem('Rating', 'Bad')
  }

  return (
    <div>
      <div className="image">
        <img
          className="Rating-logo"
          src={require('./../../Assets/Coditas Logo with guidelines copy-04.svg')}  
          alt="Logo"
        />
      </div>
      <div className="Rating-container">
        <h1 className="Rating-header">
          How would you rate your{' '}
          <span className="Rating-span">interviewer(s)?</span>
        </h1>
        <div className="Rating-dialogue">SELECT YOUR RATING</div>
        <div className="Rating-selectors">
          <div className="Rating-selector-one" onClick={() => setSelected('Awesome')}>
            <div>
              <img
                className="Rating-awesome"
                src={require('./../../Assets/icon_awesome.png')}
                alt="Logo"
              />
            </div>
            <h4 className="Rating-awesome-tag">
              <span>Awesome</span>
            </h4>
            <span className="Rating-awesome-line">Best interviewer ever!</span>
          </div>
          <div className="Rating-selector-two" onClick={() => setSelected('Good')}>
            <div>
              <img
                className="Rating-good"
                src={require('./../../Assets/icon_awesome-icons_good.png')}
                alt="Logo"
              />
            </div>
            <h4 className="Rating-good-tag">
              <span>Good</span>
            </h4>
            <span className="Rating-good-line">Nice person. Really nice.</span>
          </div>
          <div className="Rating-selector-three" onClick={() => setSelected('Neutral')}>
            <div>
              <img
                className="Rating-neutral"
                src={require('./../../Assets/icon_awesome-icons_neutral.png')}
                alt="Logo"
              />
            </div>
            <h4 className="Rating-neutral-tag">
              <span>Neutral</span>
            </h4>
            <span className="Rating-neutral-line">Ummm... Okay I guess.</span>
          </div>
          <div className="Rating-selector-four" onClick={() => setSelected('Bad')}>
            <div>
              <img
                className="Rating-bad"
                src={require('./../../Assets/icon_awesome-icons_bad.png')}
                alt="Logo"
              />
            </div>
            <h4 className="Rating-bad-tag">
              <span>Bad</span>
            </h4>
            <span className="Rating-bad-line">Needs to improve. A LOT!</span>
          </div>
        </div>
      <div className="Rating-footer">
        <Link exact className="Rating-go-to-back" to='/'>
          <span className="Rating-back"><FontAwesomeIcon icon="chevron-left" />&ensp;GO BACK</span>
          </Link>
        <Link className="Rating-link" exact to={
          {
            pathname: '/extrainfo',
            state: {		//This is used to pass state of selected item to the child component(ExtraInfo).
              selected
            }
          }} >
            <button disabled={selected === ''} className={selected !== '' ? "Rating-button-enabled" : "Rating-button"}>Next&emsp;&ensp; <FontAwesomeIcon icon="chevron-right" /></button>
          </Link>
        </div>
        </div>
    </div>
  );
};

export default Rating;
