import * as firebase from 'firebase';
import LocalForage from 'localforage';

let data = {};

const submitData = {
  async submitData() {
    //Load items from localForage to object feedbackData
    const getNames = await LocalForage.getItem('Name');
    data.name = getNames;
    const getRating = await LocalForage.getItem('Rating');
    data.rating = getRating;
    const getExtraInfo = await LocalForage.getItem('Extra Info');
    data.extraInfo = getExtraInfo;
    const getComment = await LocalForage.getItem('Comment');
    data.comment = getComment;
    
    //Firebase initialization
    var firebaseConfig = {
      apiKey: 'AIzaSyD4y7bZLJBBJprTwV057bT7V5lNbVjKa_4',
      authDomain: 'feedback-df836.firebaseapp.com',
      databaseURL: 'https://feedback-df836.firebaseio.com',
      projectId: 'feedback-df836',
      storageBucket: 'feedback-df836.appspot.com',
      messagingSenderId: '558314950421',
      appId: '1:558314950421:web:e7abb36755ac815775a31c',
    };

    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig);
    }
    const databaseRef = firebase.database().ref(`data`);

    //Push object data to firebase
    databaseRef.push().set(data);

    data = {};
    LocalForage.clear();
  },
};

export default submitData;
