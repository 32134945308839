import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faCommentAlt,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import FirstPage from './Components/Scripts/FirstPage';
import Rating from './Components/Scripts/Rating';
import Thanks from './Components/Scripts/Thanks';
import ExtraInfo from './Components/Scripts/ExtraInfo';
import Comment from './Components/Scripts/Comment';

library.add(faCheck, faCommentAlt, faChevronRight, faChevronLeft);

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={FirstPage} />
        <Route exact path="/rating" component={Rating} />
        <Route exact path="/extrainfo" component={ExtraInfo} />
        <Route exact path="/comment" component={Comment} />
        <Route exact path="/thanks" component={Thanks} />
      </Switch>
    </div>
  );
}

export default App;
