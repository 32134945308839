import React  from 'react';
import { Link } from 'react-router-dom';

import './../Styles/Thanks.css';


const Thanks = () => {
    return (
      <div>
        <img
          className="Thanks-background"
          src={require('./../../Assets/Home.svg')}
          alt="Logo"
        />
        <div className="Thanks-content">
          <h1 className="Thanks-header">Thank You!</h1>
          <p className="Thanks-paragraph">We have taken your feedback. We are glad that you felt your interviewer was <span className="Thanks-result">Awesome!</span></p>
        </div>
        <div className="Thanks-footer">
        <Link className="Thanks-link" exact to='/'>
        <span className="Thanks-home">HOME</span>
          </Link>
      </div>
      </div>
    );
  }

export default Thanks;