import React, { useState } from 'react';
import LocalForage from 'localforage';
import { Link } from 'react-router-dom';
import submitData from './submitData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './../Styles/Comment.css';

const Comment = () => {
  const [selected, isSelected] = useState(false); //Hook to disable/enable submit button
  const [length, setLength] = useState(0); //Hook to set character count

  const handleChange = (e) => {
    const length = e.target.value.length;
    setLength(length);
    length > 15 ? enableSubmit() : disableSubmit();
    const value = document.querySelector('textarea').value;
    LocalForage.setItem('Comment', `${value}`);
  }

  const handleClick = () => {
    if (length > 15) {
      submitData.submitData();
    }
  }

  function enableSubmit() {
    isSelected(true);
  }

  function disableSubmit() {
    isSelected(false);
  }

  return (
    <div className="Comment-container">
      <div className="Comment-header">
        <span>COMMENT (OPTIONAL)</span>
        <span>{`${length} / 240`}</span>
      </div>
      <div>
        <textarea
          className="Comment-textarea"
          placeholder="Write your review"
          maxLength="240"
          onChange={handleChange}
        ></textarea>
      </div>
      <div className="Comment-footer">
        <Link exact className="Comment-go-to-skip" to='/thanks'>
          <span className="Comment-skip">SKIP</span>
          </Link>
        <Link className="Comment-link" exact to={selected ? '/thanks' : '/comment'}>
          <button onClick={handleClick} className={selected ? 'Comment-button-selected' : 'Comment-button'}
        >
          SUBMIT&ensp; <FontAwesomeIcon icon="check" />
          </button>
          </Link>
      </div>
    </div>
  );
};

export default Comment;
