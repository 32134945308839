const IntroHelper = () =>
{
  setTimeout(() => {
    const intro = document.querySelector('.Intro');
    const interviewers = document.querySelector('.Interviewers-main-hidden');
    const interviewersHidden = document.querySelector(
      '.Interviewers-footer-hidden',
    );
    intro.classList.add('Intro-hidden');
    interviewers.classList.remove('Interviewers-main-hidden');
    interviewers.classList.add('Interviewers-main');
    interviewersHidden.classList.remove('Interviewers-footer-hidden');
    interviewersHidden.classList.add('Interviewers-footer');
  }, 4000);
}

export { IntroHelper };