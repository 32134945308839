import React, {useState } from 'react';
import './../Styles/InterviewersListItem.css';


const InterviewersListItem = (props) => {

  const [color, setColor] = useState('#17214d'); //Hook for changing color of selected  element.
  const [isAdded, setIsAdded] = useState('ADD'); //Hook for changing component status state.


  const handleClick = () => {
		props.selectedName(props.name, isAdded);
    if(color === '#17214d'){
      setColor('#2291F3');
      setIsAdded('REMOVE');
    } else {
 //     props.selectedName(props.name, isAdded);
      setColor('#17214d');
      setIsAdded('ADD');
    }
  }

    return (
      <div className="InterviewersListItem-container" onClick={handleClick}>
        <div className="InterviewersListItem-list">
          <div
            className="InterviewersListItem-name"
            style={{ color: `${color}` }}
          >
            {props.name}
          </div>
          <div className="InterviewersListItem-role">{props.role}</div>
        </div>
        <div className="InterviewersListItem-add">{`${isAdded}`}</div>
      </div>
    );
}
export default InterviewersListItem;
