import React from 'react';
import './../Styles/Intro.css';


function Intro() {
  return (
    <div className="Intro fade-In">
      <img
        className="Intro-logo"
        src={require('./../../Assets/logo-white.png')}
        alt="Logo"
      />
    </div>
  );
}
export default Intro;
