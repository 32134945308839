import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LocalForage from 'localforage';
import InterviewersList from './InterviewersListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuid } from 'uuid';
import './../Styles/Interviewers.css';

const defaultProps = {
	//Names of interviewers
	names: [
		{ id: uuid(), name: 'Amit Thakkar', role: 'Tech Lead' },
		{ id: uuid(), name: 'Amol Chaudhari', role: 'Tech Lead' },
		{ id: uuid(), name: 'Bhojraj Bendale', role: 'Software Developer' },
		{ id: uuid(), name: 'Chandan Mishra', role: 'Design Lead' },
		{ id: uuid(), name: 'Dileep Kumar', role: 'Software Developer' },
		{ id: uuid(), name: 'Gaurav Ghate', role: 'Tech Lead' },
		{ id: uuid(), name: 'Harish Kulkarni', role: 'Software Developer' },
		{ id: uuid(), name: 'Amit Thakkar', role: 'Tech Lead' },
		{ id: uuid(), name: 'Amol Chaudhari', role: 'Tech Lead' },
		{ id: uuid(), name: 'Bhojraj Bendale', role: 'Software Developer' },
		{ id: uuid(), name: 'Chandan Mishra', role: 'Design Lead' },
		{ id: uuid(), name: 'Dileep Kumar', role: 'Software Developer' },
		{ id: uuid(), name: 'Gaurav Ghate', role: 'Tech Lead' },
		{ id: uuid(), name: 'Harish Kulkarni', role: 'Software Developer' },
	],
};

const Interviewers = () => {
	const [state, setState] = useState(defaultProps.names); //Hook for the names of the interviewers.
	const [isEmpty, setIsEmpty] = useState(true); //Hook to disable/enable next button
	const [arr, setArr] = useState([]); //Hook for selected interviewer names.
	const [selected, setSelected] = useState(0); //Hook for changing added interviewrs component value.

	const addInterviewers = (value, isAdded) => {
		if (isAdded === 'ADD') {
			let newList = arr;
			newList.push(value);
			setArr(newList);
			setSelected(newList.length);
		} else {
			let newList = arr;
			const index = newList.indexOf(value);
			newList.splice(index, 1); //Remove unselected item from the array.
			setArr(newList);
			setSelected(selected - 1);
		}
		enableButton();

		LocalForage.setItem('Name', arr);
	};

	const enableButton = () => {
		if (arr.length === 0) {
			setIsEmpty(true);
		} else {
			setIsEmpty(false);
		}
	};

	//This function takes the search term and filters the names from the default list. Then it moves all the matched cases to an array.
	const handleChange = e => {
		const searchTerm = e.target.value.trim();
		const filteredInterviewers = defaultProps.names.filter(interviewer => {
			return interviewer.name.toLowerCase().includes(searchTerm.toLowerCase());
		});
		const result = filteredInterviewers.flat();
		setState(result);
	};

	return (
		<div className="Interviewers-main-hidden">
			<div className="Interviewers-image">
				<img
					className="Interviewers-logo"
					src={require('./../../Assets/Coditas Logo with guidelines copy-04.svg')}
					alt="Logo"
				/>
			</div>
			<div className="Interviewers-background">
				<div className="Interviewers-container">
					<h1 className="Interviewers-header">Interviewers</h1>
					<div className="Interviewers-searchbar-container">
						<input
							name="Interviewers"
							className="Interviewers-searchbar"
							placeholder="Search Interviewers"
							value={state.search}
							onChange={handleChange}
						></input>
						<img
							className="Interviewers-searchbar-icon"
							src={require('./../../Assets/mag-lens.svg')}
							alt="search"
						/>
					</div>
					<div className="Interviewers-added">{`${selected}`} ADDED</div>
					<div className="Interviewers-list">
						{state.map(n => (
							<InterviewersList key={n.id} name={n.name} role={n.role} selectedName={addInterviewers} />
						))}
					</div>
					<div className="Interviewers-footer-hidden">
						<Link className="Interviewers-link" exact to="/rating">
							<button
								disabled={isEmpty}
								className={isEmpty ? 'Interviewers-button' : 'Interviewers-button-enabled'}
							>
								Next&emsp;&ensp; <FontAwesomeIcon icon="chevron-right" />
							</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Interviewers;
