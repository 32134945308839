import React from 'react';
import Intro from './Intro';
import Interviewers from './Interviewers';
import { IntroHelper } from './IntroHelper';

function FirstPage() {
    return (
      <>
        <Intro />
        {IntroHelper()}
        <Interviewers />
      </>
    );
  }

export default FirstPage;